<template>
  <el-header class="header" height="80px">
    <el-row>
      <el-col :span="4" class="logo"
        ><img
          :src="require('../../../assets/images/website/logo.png')"
          class="img"
        />
      </el-col>
      <el-col :span="16" :offset="4" class="menu">
        <el-menu
          :default-active="active"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#fcb316"
          @select="menuSelect"
        >
          <el-menu-item index="home">网站首页</el-menu-item>
          <el-menu-item index="data"> 九维数据 </el-menu-item>
          <el-menu-item index="expert"> 专家库 </el-menu-item>
          <el-menu-item index="report"> 白皮书 </el-menu-item>
          <el-menu-item index="enterprise">企业服务 </el-menu-item>
          <el-menu-item index="person">个人服务 </el-menu-item>
          <el-menu-item index="government"> 政务服务 </el-menu-item>
          <el-menu-item index="about"> 关于我们 </el-menu-item>
        </el-menu>
      </el-col>
      <!-- <el-col :span="3" class="ewxq">
        <el-row>
          <el-col :span="8"
            ><img
              :src="require('../../../assets/images/website/ewx.png')"
              class="img" /></el-col
          ><el-col :span="16" class="tips fc-orange"
            ><div>客服电话</div>
            <div>15029291243</div>
            <div>（扫码添加微信）</div>
          </el-col></el-row
        >
      </el-col> -->
    </el-row>
  </el-header>
</template>
<script>
import { ElHeader, ElMenu, ElMenuItem } from 'element-plus'
export default {
  components: {
    [ElHeader.name]: ElHeader,
    [ElMenu.name]: ElMenu,
    [ElMenuItem.name]: ElMenuItem
  },
  data () {
    return {
      backUrl: ''
    }
  },
  props: {
    active: { type: String }
  },
  mounted () {
  },
  methods: {
    menuSelect (val) {
      var path = this.$route.path
      if (val === 'home') {
        this.$router.replace({ path: '/website/home' })
      } else if (val === 'data') {
        this.$router.replace({ path: '/website/data' })
      } else if (val === 'expert') {
        this.$router.replace({ path: '/website/expert' })
      } else if (val === 'report') {
        this.$router.replace({ path: '/website/report' })
      } else if (val === 'enterprise') {
        if (path === '/website/home') {
          window.scrollTo({
            top: 1700,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          this.$router.replace({ path: '/website/home', query: { service: 'enterprise' } })
        }
      } else if (val === 'person') {
        if (path === '/website/home') {
          window.scrollTo({
            top: 2300,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          this.$router.replace({ path: '/website/home', query: { service: 'person' } })
        }
      } else if (val === 'government') {
        if (path === '/website/home') {
          window.scrollTo({
            top: 2800,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          this.$router.replace({ path: '/website/home', query: { service: 'government' } })
        }
      } else if (val === 'about') {
        this.$router.replace({ path: '/website/about' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 70px;
  .logo {
    .img {
      height: 70px;
    }
  }
  .menu {
    margin-top: 8px;
    text-align: center;
  }
  .el-menu-item {
    font-size: 15px;
    font-weight: 600;
  }
  .ewxq {
    line-height: 60px;
    font-weight: 800;
    text-align: right;
    .img {
      margin-top: 5px;
      height: 65px;
    }
    .tips {
      padding-top: 8px;
      font-size: 12px;
      text-align: left;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
    }
  }
}
</style>
