<template>
  <el-footer height="100px" class="footer">
    <el-row>
      <el-col :span="24">
        <div class="icp">
          Copyright © 2022-2023 九维智库（西安）<br />
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >陕ICP备2023009594号</a
          >
        </div>
      </el-col>
    </el-row>
  </el-footer>
</template>
<script>
import { ElFooter } from 'element-plus'
export default {
  components: {
    [ElFooter.name]: ElFooter
  },
  data () {
    return {}
  },
  props: {
    active: { type: String }
  },
  mounted () {
  },
  methods: {
    menuSelect (val) {
      console.log(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  .title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
  }
  .item {
    margin-top: 10px;
    font-size: 13px;
  }
  .icp {
    margin-top: 20px;
    padding: 10px 0px;
    font-size: 13px;
  }
}
</style>
